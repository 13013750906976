export enum RankingCategory {
  Dapps = 'dapps',
  // Cryptocurrency = 'cryptocurrencies',
  // Dex = 'dex',
  // Cex = 'cex',
  // Nft = 'nft',
}

const categoryLabelKeys: Record<RankingCategory, string> = {
  [RankingCategory.Dapps]: 'coins.dapp',
  // [RankingCategory.Cryptocurrency]: 'coins.cryptocurrency',
  // [RankingCategory.Dex]: 'coins.dex',
  // [RankingCategory.Cex]: 'coins.cex',
  // [RankingCategory.Nft]: 'NFT',
};

export const getCategoryLabelKey = (category: RankingCategory): string => {
  return categoryLabelKeys[category];
};

export const RANKING_CATEGORIES = Object.values(RankingCategory).map(
  (category: RankingCategory) => ({
    id: category,
    labelKey: getCategoryLabelKey(category),
  }),
);

export const MAX_RANKING_METRICS_NUMBER = 12;
